<template>
    <div v-if="showButton">
        <button id="help-button" class="btn rounded-circle btn-primary" title="Need help?">
            <i class="fas fa-question"></i>
        </button>
        <b-popover target="help-button" triggers="click blur" placement="top">
            <template #title>Need help?</template>
            Don't hesitate to <a href="mailto:partnership@boredpanda.com">contact us!</a>
        </b-popover>
    </div>
</template>

<script>
import {BPopover} from 'bootstrap-vue';

export default {
    name: 'need-help',
    components: {BPopover},
    computed: {
        showButton() {
            return !['homepage', 'login'].includes(this.$route.name);
        }
    },
};
</script>
<style scoped lang="scss">
#help-button {
    position: fixed;
    bottom: 39px;
    right: 39px;
    padding: 15px;
    width: 55px;
    height: 55px;

    @media (max-width: 767px) {
        bottom: 15px;
        right: 15px;
    }

    i {
        vertical-align: middle;
        height: 20px;
        line-height: 20px;
    }
}
</style>
