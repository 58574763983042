<template>
    <div>
        <list-table ref="listTable" :endpoint="endpoint" :fields="fields" :sortDesc="false" :sortKeys="sortKeys">
            <template #header>
                <div class="row">
                    <div class="col-lg-8">
                        <h3>Users</h3>
                    </div>
                    <div class="col-lg-4 text-right">
                        <button type="button" class="btn btn-sm btn-primary" @click="addUser">
                            Add user
                        </button>
                    </div>
                </div>
            </template>

            <template #cell(role)="data">
                    <span v-for="(role, index) in data.item.roles" :key="index">
                        <span v-if="index !== 0">,</span>
                        {{ $constants.roleName[role.name] }}
                    </span>
            </template>

            <template #cell(status)="data">
                {{ data.item.is_active ? 'Active' : 'Inactive' }}
            </template>

            <template #cell(actions)="data">
                <a href="#" class="ml-2" title="Edit user"
                   @click.prevent="editUser(data.item)">
                    <i class="fas fa-edit"></i>
                </a>
            </template>
        </list-table>
        <user-form :id="'user-form'" v-model="user" @after-submit="afterSubmit"></user-form>
    </div>

</template>

<script>

import ListTable from '@/components/ListTable.vue';
import UserForm from '@/views/Settings/UserForm.vue';

export default {
    name: 'users',
    components: {UserForm, ListTable},
    data() {
        return {
            endpoint: './admin/users/list.json',
            fields: [
                {key: 'id', label: '#', sortable: true},
                {key: 'name', label: 'Name', sortable: true},
                {key: 'email', label: 'Email', sortable: false},
                {key: 'created_at', label: 'Date added', sortable: true},
                {key: 'role', label: 'Role', sortable: false},
                {key: 'status', label: 'Status', sortable: true},
                {key: 'actions', label: '', sortable: false},
            ],
            sortKeys: {
                'id': 'id',
                'name': 'name',
                'email': 'email',
                'created_at': 'created_at',
                'status': 'is_active',
            },
            user: null,
        };
    },
    methods: {
        editUser(user) {
            this.user = user;
            this.$bvModal.show('user-form');
        },
        addUser() {
            this.user = null;
            this.$bvModal.show('user-form');
        },
        afterSubmit() {
            this.$refs.listTable.searchEntries();
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
