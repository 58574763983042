import '@/assets/vendor/nucleo/css/nucleo.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import '@/assets/scss/argon.scss';
import 'primevue/resources/themes/saga-green/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import globalComponents from './globalComponents';
import globalDirectives from './globalDirectives';
import SidebarPlugin from '@/components/SidebarPlugin/index'
import NotificationPlugin from '@/components/NotificationPlugin/index'
import {ModalPlugin} from 'bootstrap-vue';
import {ToastPlugin} from 'bootstrap-vue';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';

export default {
    install(Vue) {
        Vue.use(globalComponents);
        Vue.use(globalDirectives);
        Vue.use(SidebarPlugin);
        Vue.use(NotificationPlugin);
        Vue.use(ModalPlugin);
        Vue.use(ToastPlugin);
        Vue.use(PrimeVue);
        Vue.use(ConfirmationService);
    }
};
