<template>
    <div class="row full-page" v-if="$auth.check($constants.role.exclusive_user)">
        <div class="col-lg-12">
            <list-table ref="listTable" :endpoint="endpoint" :fields="fields" :requestParams="requestParams">
                <template #header>
                    <div class="row">
                        <div class="col-lg-12 text-right">
                            <button type="button" class="btn btn-sm btn-primary"
                                    @click="$bvModal.show('new-video-modal')">
                                Upload video
                            </button>
                            <new-raw-video @after-submit="afterSubmit"></new-raw-video>
                        </div>
                    </div>
                </template>

                <template #cell(video)="data">
                    <b-embed
                        type="video"
                        preload="meta"
                        controls="controls"
                        aspect="16by9"
                        :src="data.item.url"
                    ></b-embed>
                </template>

            </list-table>
        </div>
    </div>
</template>

<script>

import ListTable from '@/components/ListTable.vue';
import NewRawVideo from '@/views/Library/NewRawVideo.vue';
import {BEmbed} from 'bootstrap-vue';

export default {
    name: 'raw-videos',
    components: {NewRawVideo, ListTable, BEmbed},
    data() {
        return {
            endpoint: './user/videos/listRaw.json',
            fields: [
                {key: 'video', label: 'Video', sortable: false},
                {key: 'title', label: 'Title', sortable: true},
                {key: 'created_at', label: 'Uploaded at', sortable: true},
            ],
        }
    },
    computed: {
        requestParams() {
            return '&userId=' + (this.$auth.user() ? this.$auth.user().id : '');
        },
    },
    methods: {
        afterSubmit() {
            this.$refs.listTable.searchEntries();
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
