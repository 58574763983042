<template>
    <nav class="navbar navbar-vertical pt-2 fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
        <div class="container-fluid">
            <router-link to="/" class="active pt-0 pb-5 navbar-brand d-none d-md-block">
                <img :src="logo" class="navbar-brand-img">
            </router-link>
            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>

            <div v-if="showDashboardTypes()">
                <span class="tabs-title">Agreement type:</span>
                <b-tabs>
                    <b-tab title="Exclusive"
                           :active="$isExclusiveDashboard"
                           @click="changeDashboardType($constants.dashboardType.exclusive)"></b-tab>
                    <b-tab title="Non-Exclusive"
                           :active="!$isExclusiveDashboard"
                           @click="changeDashboardType($constants.dashboardType.non_exclusive)"></b-tab>
                </b-tabs>
            </div>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                    <div class="media align-items-center" v-if="$auth.check()">
                        <span class="mb-0 text-sm font-weight-bold user-name">{{ $auth.user().name }}</span>
                    </div>
                </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img :src="logo">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <div v-if="$auth.check($constants.role.user)">
                    <ul class="navbar-nav">
                        <slot name="links">
                        </slot>
                    </ul>

                    <ul class="navbar-nav">
                        <slot name="settings-links">
                        </slot>
                    </ul>
                </div>
                <div v-if="$auth.check($constants.role.admin)">
                    <h6 class="navbar-heading text-muted">Admin</h6>
                    <ul class="navbar-nav">
                        <slot name="admin-links">
                        </slot>
                    </ul>
                </div>
                <div v-if="$auth.check($constants.role.user)">
                    <hr class="my-3">
                    <ul class="navbar-nav">
                        <slot name="payments">
                        </slot>
                    </ul>
                    <div v-if="$auth.user().has_exclusive_contract">
                        <hr class="my-3">
                        <ul class="navbar-nav">
                            <slot name="submit-video">
                            </slot>
                        </ul>
                    </div>
                </div>
                <hr class="my-3">
                <div v-if="$auth.check()">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="/logout" @click.prevent="logout" class="nav-link">
                                <i class="ni ni-user-run text-dark"></i>
                                <span class="nav-link-text">Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="copyright text-right pt-3 d-md-none">
                    <span>© {{ year }} Bored Panda</span>
                </div>
            </div>

            <div class="copyright text-right d-none d-md-block">
                <span>© {{ year }} Bored Panda</span>
            </div>
        </div>
    </nav>
</template>
<script>
import NavbarToggleButton from '@/components/NavbarToggleButton.vue'
import { BTabs, BTab } from 'bootstrap-vue'

export default {
    name: 'sidebar',
    components: {
        NavbarToggleButton,
        'b-tabs': BTabs,
        BTab,
    },
    props: {
        logo: {
            type: String,
            default: 'img/brand/boredpanda_logo_horizontal.svg',
            description: 'Sidebar app logo'
        },
        autoClose: {
            type: Boolean,
            default: true,
            description: 'Whether sidebar should autoclose on mobile when clicking an item'
        }
    },
    data() {
        return {
            year: (new Date()).getFullYear(),
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        };
    },
    methods: {
        submitVideo() {
            this.$bvModal.show('submit-video');
        },
        async logout() {
            await this.$store.commit('set_user', null);
            await this.$auth.logout({});
        },
        closeSidebar() {
            this.$sidebar.displaySidebar(false)
        },
        showSidebar() {
            this.$sidebar.displaySidebar(true)
        },
        changeDashboardType(type) {
            localStorage.setItem('dashboardType', type);
            location.reload();
        },
        showDashboardTypes() {
            return this.$auth.check(this.$constants.role.user)
                && this.$auth.user().has_non_exclusive_contract
                && this.$auth.user().has_exclusive_contract;
        }
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.nav-link i.ni {
    top: 0;
}

.user-name {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.copyright, .tabs {
    font-size: 14px;
}

.tabs-title {
    font-size: 12px;
}
</style>
