<template>
    <div>
        <list-table ref="listTable" :endpoint="endpoint" :fields="fields" :sortDesc="true" :sortKeys="sortKeys">
            <template #cell(video)="data">
                <a :href="data.item.link" target="_blank" class="video">
                    <img class="img-fluid" :src="data.item.thumb" :title="data.item.title"
                         v-b-tooltip.right.hover>
                </a>
            </template>
            <template #cell(releases)="data">
                <div v-for="project in data.item.projects" v-bind:key="project.id">
                    <div v-for="release in project.project_releases" v-bind:key="release.id">
                        <a :href="release.external_video_link" target="_blank">{{ release.external_video_link }}</a>
                    </div>
                </div>
            </template>
<!--            <template #cell(actions)="data">-->
<!--                <a href="#" class="ml-2" title="Add Release"-->
<!--                   @click.prevent="addRelease(data.item)">-->
<!--                    <i class="fas fa-plus"></i>-->
<!--                </a>-->
<!--            </template>-->
        </list-table>
        <add-release ref="addRelease" @after-submit="refresh"></add-release>
    </div>
</template>

<script>

import ListTable from '@/components/ListTable.vue';
import {VBTooltip} from 'bootstrap-vue';
import AddRelease from "@/views/Videos/AddRelease";

export default {
    name: 'list-videos',
    components: {AddRelease, ListTable},
    directives: {'b-tooltip': VBTooltip},
    data() {
        return {
            endpoint: './admin/videos/list.json',
            fields: [
                {key: 'video', label: 'Video', sortable: false},
                {key: 'video_id', label: 'ID', sortable: true},
                {key: 'artist.artist_id', label: 'Artist ID', sortable: false},
                {key: 'artist.name', label: 'Artist name', sortable: false},
                {key: 'deal.deal_id', label: 'Deal ID', sortable: false},
                {key: 'permission_name', label: 'Permission', sortable: true},
                {key: 'created_at', label: 'Date added', sortable: true},
                {key: 'releases', label: 'Releases', sortable: false},
                {key: 'actions', label: '', sortable: false},
            ],
            sortKeys: {
                'video_id': 'id',
                'permission_name': 'permission',
            },
        }
    },
    methods: {
        addRelease(video) {
            this.$refs.addRelease.openModal(video);
        },
        refresh() {
            this.$refs.listTable.fetchEntries();
        }
    }
};
</script>

<style lang="scss" scoped>
.img-fluid {
    max-width: 150px;
}
</style>
