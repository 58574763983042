<template>
    <div>
        <div class="header bg-white py-7 py-lg-8">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 col-md-6">
                            <h1>Create new password</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </div>

        <div class="container mt--8 pb-5">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7">
                    <div class="card bg-secondary shadow-lg border">
                        <div class="card-body px-lg-5 py-lg-5">
                            <b-alert v-model="showSuccess" v-text="successMessage"
                                     variant="success">
                            </b-alert>
                            <b-alert v-model="showError" v-text="errorMessage" variant="danger">
                            </b-alert>
                            <form role="form" @submit.prevent="reset">
                                <base-input class="input-group-alternative"
                                            placeholder="Password"
                                            type="password"
                                            addon-left-icon="ni ni-lock-circle-open"
                                            v-model="password">
                                </base-input>
                                <base-input class="input-group-alternative"
                                            placeholder="Confirm password"
                                            type="password"
                                            addon-left-icon="ni ni-lock-circle-open"
                                            v-model="confirmedPassword">
                                </base-input>

                                <div class="text-center">
                                    <button type="submit" class="btn btn-primary my-4">Change</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {BAlert} from 'bootstrap-vue';

export default {
    name: 'login',
    components: {BAlert},
    data() {
        return {
            password: '',
            confirmedPassword: '',
            errorMessage: '',
            showError: false,
            showSuccess: false,
            successMessage: '',
        }
    },
    methods: {
        reset() {
            this.axios.post('/auth/reset.json', {
                token: this.$route.query.token,
                email: this.$route.query.email,
                password: this.password,
                password_confirmation: this.confirmedPassword,
            }).then(x => x.data).then((data) => {
                this.successMessage = data.message;
                this.showSuccess = true;
                this.showError = false;
                setTimeout(() => {
                    this.$router.push('login');
                }, 2);
            }).catch((error) => {
                if (error.response.status === 422) {
                    this.errorMessage = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
                    this.showError = true;
                    this.showSuccess = false;
                }
            });
        },
    },
}
</script>
<style>
</style>
