<template>
    <div>
        <b-modal :id="id || 'modal'" scrollable size="md" hide-footer>
            <template slot="modal-title">
                <span v-if="editMode">Edit user</span>
                <span v-else>Add user</span>
            </template>
            <spinner v-model="loading"></spinner>
            <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
            <div class="row" v-if="userData">
                <div class="col-lg-12">
                    <base-input
                        v-model="userData.name"
                        :label="'Name'"
                        :error="errors.name.message"
                        :input-classes="'form-control'"
                        :class="'form-group mb-2'"
                    ></base-input>

                    <base-input
                        v-model="userData.email"
                        :label="'Email'"
                        :disabled="editMode"
                        :error="errors.email.message"
                        :input-classes="'form-control'"
                        :class="'form-group mb-2'"
                    ></base-input>

                    <div class="form-group mb-2">
                        <label class="form-control-label">Status</label>
                        <multiselect
                            v-model="userData.status"
                            :options="formData.statuses"
                            label="name"
                            track-by="value"
                            placeholder="Choose..."
                            :class="[{'is-invalid': errors.status.message}]"
                        ></multiselect>
                        <div class="invalid-feedback text-danger"
                             v-if="errors.status.message"
                             v-text="errors.status.message"
                        ></div>
                    </div>

                    <base-checkbox :class="{'is-invalid': errors.super_admin.message}"
                                   v-model="userData.superAdmin"
                                   v-if="superAdmin"
                                   class="form-group mb-2">
                        Super Admin
                    </base-checkbox>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12 text-right modal-footer-border">
                    <button type="button" @click="$bvModal.hide('user-form')" class="btn btn-light mt-3">Close
                    </button>
                    <button type="button" @click="submit" class="btn btn-primary mt-3">Submit</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

import {filter} from 'lodash';
import Spinner from '@/components/Spinner.vue';
import ProcessError from '@/mixins/ProcessError';
import {BAlert} from 'bootstrap-vue';

export default {
    name: 'user-form',
    components: {Spinner, BAlert},
    mixins: [ProcessError],
    props: {
        value: {
            type: Object,
            description: 'User object',
        },
        id: {
            type: String,
            description: 'Modal\'s id',
        },
    },
    data() {
        return {
            userData: {
                id: null,
                name: null,
                email: null,
                status: null,
                superAdmin: null,
            },
            formData: {
                statuses: [
                    {value: 1, name: 'Active'},
                    {value: 0, name: 'Inactive'},
                ],
            },
            loading: false,
            errors: {
                form: {show: false, message: ''},
                name: {message: ''},
                email: {message: ''},
                status: {message: ''},
                super_admin: {message: ''},
            },
        };
    },
    computed: {
        user: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        editMode() {
            return this.user !== null;
        },
        superAdmin() {
            return this.$auth.check(this.$constants.role.super_admin)
        }
    },
    watch: {
        user() {
            this.initUser();
        }
    },
    methods: {
        initUser() {
            this.userData.id = this.user ? this.user.id : null;
            this.userData.name = this.user ? this.user.name : null;
            this.userData.email = this.user ? this.user.email : null;
            this.userData.superAdmin = this.user
                ? this.user.roles.map(x => x.name).includes(this.$constants.role.super_admin)
                : null;
            this.userData.status = this.user ? filter(this.formData.statuses, (status) => {
                return status.value === this.user.is_active;
            })[0] : null;
        },
        submit() {
            this.resetErrors();
            this.loading = true;
            this.axios.post('./admin/users/create.json', this.userData).then(() => {
                this.$emit('after-submit');
                this.$bvModal.hide(this.id || 'modal');
                this.loading = false;
                Object.assign(this.$data.userData, this.$options.data().userData);
            }).catch((error) => {
                this.processError(error);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
