<template>
    <div class="main-content bg-default">
        <!-- Navbar -->
        <base-nav class="navbar-top navbar-horizontal navbar-dark bg-white"
                  containerClasses="px-3 container"
                  expand>
            <router-link slot="brand" class="navbar-brand" to="/home">
                <img src="img/brand/boredpanda_logo_horizontal.svg"/>
            </router-link>

            <template v-slot="{closeMenu}">
                <!-- Collapse header -->
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img src="img/brand/boredpanda_logo_horizontal.svg">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <button type="button"
                                    @click="closeMenu"
                                    class="navbar-toggler"
                                    aria-label="Toggle sidenav">
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Navbar items -->
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon text-default" to="/login" v-if="!$auth.check()">
                            <i class="ni ni-key-25"></i>
                            <span class="nav-link-inner--text">Login</span>
                        </router-link>
                        <router-link class="nav-link nav-link-icon text-default" to="/" v-else>
                            <i class="ni ni-shop"></i>
                            <span class="nav-link-inner--text">Dashboard</span>
                        </router-link>
                    </li>
                </ul>
            </template>
        </base-nav>

        <slide-y-up-transition mode="out-in" origin="center top">
            <router-view></router-view>
        </slide-y-up-transition>
    </div>
</template>
<script>
import {SlideYUpTransition} from 'vue2-transitions'

export default {
    name: 'auth-layout',
    components: {SlideYUpTransition}
}
</script>
<style lang="scss" scoped>
.main-content {
    min-height: 100vh;
}
</style>
