<template>
    <stats-card title="Payment"
                type="gradient-blue"
                sub-title="Reward Payment Method"
                icon="ni ni-hat-3"
    >
        <template slot="footer">
            <ul>
                <li>Rewards are calculated and paid based on 3 second views.</li>
                <li>Reward for each yearly quarter is calculated and paid separately (views from the same video reached in previous yearly quarters are not added together).</li>
                <li>Payments will reach you no later than 20 days after the end of every yearly quarter (if the eligible amount of views was reached).</li>

            </ul>
        </template>
    </stats-card>
</template>

<script>

export default {
    name: 'exclusive-rewards-info',
};
</script>

<style lang="scss" scoped>
    ul {
        padding-inline-start: 1rem;
    }
</style>
