<template>
    <div>
        <div class="header bg-white py-7 py-lg-8">
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </div>

        <div class="container mt--8 pb-5">
            <spinner v-model="loading"></spinner>
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="card bg-secondary shadow-lg border">
                        <div class="card-header bg-transparent">
                            <div class="text-left mt-2">
                                <h3>Registration</h3>
                                <p class="mt-3 mb-0">
                                    Bored Panda Studios team is excited to have you on board! To begin your journey with
                                    us, please fill in the registration form below:
                                </p>
                            </div>
                        </div>
                        <div class="card-body px-lg-5 py-lg-5">
                            <div class="row">
                                <div class="col-lg-6 col-sm-12">
                                    <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger">
                                    </b-alert>
                                    <form role="form">

                                        <base-input class="form-group mb-3"
                                                    label="Your full name"
                                                    :error="errors.name.message"
                                                    v-model="name">
                                        </base-input>

                                        <base-input class="form-group mb-3"
                                                    label="Email"
                                                    disabled="disabled"
                                                    autocomplete="username"
                                                    type="email"
                                                    :error="errors.email.message"
                                                    v-model="email">
                                        </base-input>

                                        <base-country class="form-group mb-3"
                                                      label="Billing country"
                                                      :error="errors.billing_country.message"
                                                      v-model="billingCountry">
                                        </base-country>

                                        <div class="form-group mb-3">
                                            <label class="form-control-label">Phone number</label>
                                            <vue-phone-number-input v-model="phoneNumber"
                                                                    :no-example="true"
                                                                    @update="updatePhoneNumber"
                                                                    ref="phoneNumber"
                                                                    :error="errors.phone_number.message !== ''"
                                                                    :class="{'is-invalid': errors.phone_number.message}"
                                            ></vue-phone-number-input>
                                            <div class="invalid-feedback text-danger" v-if="errors.phone_number.message"
                                                 v-text="errors.phone_number.message"></div>
                                        </div>

                                        <base-input class="form-group mb-3"
                                                    label="Create a password"
                                                    type="password"
                                                    autocomplete="new-password"
                                                    :error="errors.password.message"
                                                    v-model="password">
                                        </base-input>

                                        <base-input class="form-group mb-3"
                                                    label="Confirm your password"
                                                    type="password"
                                                    autocomplete="new-password"
                                                    v-model="confirmedPassword">
                                        </base-input>

                                        <div class="row my-4">
                                            <div class="col-12">
                                                <base-checkbox
                                                    v-model="privacyPolicy"
                                                    :error="errors.privacy_policy.message"
                                                    class="custom-control-alternative">
                                                <span class="text-muted">I have read, fully understood and agree to be
                                                    bound by the
                                                    <a target="_blank" href="/privacy-policy">Privacy Policy</a>
                                                </span>
                                                </base-checkbox>
                                                <base-checkbox
                                                    v-model="terms"
                                                    :error="errors.terms.message"
                                                    class="custom-control-alternative">
                                                <span class="text-muted">I have read, fully understood and agree to be
                                                    bound by the
                                                    <a target="_blank"
                                                       href="/terms-and-conditions">Terms and Conditions</a>
                                                </span>
                                                </base-checkbox>
                                            </div>
                                        </div>
                                        <div class="text-left">
                                            <base-button @click="register" type="primary" class="my-4">
                                                Register
                                            </base-button>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-lg-6 order-first order-lg-last">
                                    <img class="img-fluid" src="img/brand/panda_landing_rocket.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Spinner from '@/components/Spinner';
import {BAlert} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';

export default {
    name: 'register',
    components: {Spinner, BAlert, VuePhoneNumberInput},
    mixins: [ProcessError],
    data() {
        return {
            token: null,
            email: null,
            id: null,
            name: '',
            billingCountry: null,
            phoneNumber: null,
            phoneNumberObject: null,
            password: '',
            confirmedPassword: '',
            loading: false,
            privacyPolicy: false,
            terms: false,
            errors: {
                form: {show: false, message: ''},
                name: {show: false, message: ''},
                email: {show: false, message: ''},
                billing_country: {show: false, message: ''},
                password: {show: false, message: ''},
                privacy_policy: {show: false, message: ''},
                terms: {show: false, message: ''},
                phone_number: {show: false, message: ''},
            }
        }
    },
    mounted() {
        this.token = this.$route.query.token;
        this.email = this.$route.query.email;
        this.getUser();
    },
    methods: {
        resetErrors() {
            Object.assign(this.$data.errors, this.$options.data().errors);
        },
        updatePhoneNumber(object) {
            this.phoneNumberObject = object;
        },
        getUser() {
            this.loading = true;
            this.axios.get('/auth/user-invited.json?token=' + this.token + '&email=' + this.email)
                .then(x => x.data).then(user => {
                this.id = user.id;
                this.name = user.name;
                this.address = user.address;
                this.loading = false;
            }).catch(error => {
                if (error.response.data.message === 'User not found') {
                    this.$router.push('login');
                } else {
                    this.processError(error);
                }
            });
        },
        register() {
            this.resetErrors();
            this.loading = true;
            this.$auth.register({
                data: {
                    id: this.id,
                    email: this.email,
                    name: this.name,
                    password: this.password,
                    password_confirmation: this.confirmedPassword,
                    billing_country: this.billingCountry ? this.billingCountry.code : null,
                    phone_number: this.phoneNumberObject,
                    privacy_policy: this.privacyPolicy,
                    terms: this.terms,
                },
            }).then(() => {
                this.$ga.event('User', 'Register', this.$auth.user().id.toString());
            }).catch(this.processError);
        },
    },
}
</script>
<style scoped lang="scss">
.custom-control {
    z-index: 0;
}
</style>
