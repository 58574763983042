<template>
    <div class="full-page">
        <div class="row" v-if="paidUser">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-6 col-sm-12 m-0 p-0">
                        <div class="col mb-4">
                            <current-month-rewards></current-month-rewards>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 m-0 p-0" v-if="exclusiveDashboard">
                        <div class="col mb-4">
                            <exclusive-rewards-info></exclusive-rewards-info>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-lg-12">
                <videos></videos>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <invoices :type="invoicesType()"></invoices>
            </div>
        </div>
    </div>
</template>
<script>

import CurrentMonthRewards from '@/views/Rewards/CurrentMonthRewards.vue';
import Invoices from '@/views/Rewards/Invoices.vue';
import Videos from '@/views/Rewards/Videos.vue';
import ExclusiveRewardsInfo from '@/views/Rewards/ExclusiveRewardsInfo.vue';

export default {
    components: {ExclusiveRewardsInfo, Invoices, Videos, CurrentMonthRewards},
    data() {
        return {
            user: null,
        };
    },
    computed: {
        paidUser() {
            return this.user &&
                this.user.active_contract &&
                this.user.active_contract.payment_type !== this.$constants.paymentType.free;
        },
        exclusiveDashboard() {
            return this.$isExclusiveDashboard;
        }
    },
    mounted() {
        this.$store.dispatch('getUser').then(user => {
            this.user = user;
        })
    },
    methods: {
        invoicesType() {
            return localStorage.dashboardType;
        }
    }
};
</script>
<style lang="scss" scoped>
</style>
