<template>
    <div class="row full-page">
        <div class="col-lg-12">
            <spinner v-model="loading"></spinner>
            <b-alert v-model="error" variant="danger">
                Error! Please try again!
            </b-alert>
            <b-alert v-model="success" variant="success">
                Success!
            </b-alert>

            <list-table ref="listTable" :endpoint="endpoint" :fields="fields" :sortDesc="true" :sortKeys="sortKeys">
                <template #header>
                    <div class="row">
                        <div class="col-lg-12 text-right">
                            <button type="button" class="btn btn-sm btn-primary" @click="exportPayments">
                                Export payments
                            </button>
                        </div>
                    </div>
                </template>

                <template #cell(artist_id)="data">
                    <a v-if="data.item.user.artist" target="_blank"
                       :href="'https://app.pipelinecrm.com/people/' + data.item.user.artist.artist_id">
                        {{ data.item.user.artist.artist_id }}
                    </a>
                </template>

                <template #cell(status)="data">
                    {{ data.item.is_paid ? 'Paid' : 'Not Paid' }}
                    <a v-if="!data.item.is_paid" class="ml-2" href="#" @click.prevent="markAsPaid(data.item)"
                       title="Mark as paid">
                        <i class="fas fa-money-bill-alt"></i>
                    </a>
                </template>

                <template #cell(actions)="data">
                    <a :href="data.item.url" class="ml-2" title="Show invoice" target="_blank">
                        <i class="fas fa-external-link-alt"></i>
                    </a>
                    <a href="#" @click.prevent="updateInvoice(data.item)" class="ml-2" title="Update invoice"
                       target="_blank">
                        <i class="fas fa-sync"></i>
                    </a>
                    <a href="#" @click.prevent="deleteInvoice(data.item)" v-if="!data.item.is_paid"
                       class="ml-2 text-red" title="Delete invoice" target="_blank">
                        <i class="fas fa-trash-alt"></i>
                    </a>
                </template>
            </list-table>
        </div>
    </div>
</template>
<script>

import ListTable from '@/components/ListTable.vue';
import Spinner from '@/components/Spinner.vue';
import {BAlert} from 'bootstrap-vue';
import DownloadFile from '@/mixins/DownloadFile';

export default {
    name: 'invoices',
    components: {ListTable, Spinner, BAlert},
    mixins: [DownloadFile],
    data() {
        return {
            endpoint: './admin/invoices/list.json',
            fields: [
                {key: 'id', label: '#', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'user.name', label: 'Name', sortable: false},
                {key: 'user.email', label: 'Email', sortable: false},
                {key: 'artist_id', label: 'Artist id', sortable: false},
                {key: 'status', label: 'Status', sortable: true},
                {key: 'actions', label: '', sortable: false},
            ],
            sortKeys: {
                'status': 'is_paid',
            },
            loading: false,
            error: false,
            success: false,
        }
    },
    methods: {
        markAsPaid(invoice) {
            this.$confirm.require(
                {
                    message: `Are you sure want to pay for this invoice?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.axios.post('./admin/invoices/pay.json', {
                            invoice_id: invoice.id
                        }).then(x => x.data).then(() => {
                            invoice.is_paid = 1;
                        });
                    }
                }
            );
        },
        exportPayments() {
            this.axios.post('./admin/invoices/export.json', {}, {
                responseType: 'arraybuffer'
            }).then(x => x.data).then(response => this.downloadFile(response, 'payments.zip')).catch(error => {
                console.log(error);
            });
        },
        updateInvoice(invoice) {
            this.$confirm.require(
                {
                    message: `Are you sure want to update this invoice?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.loading = true;
                        this.axios.post('./admin/invoices/update.json', {
                            invoice_id: invoice.id
                        }).then(x => x.data).then((data) => {
                            invoice.url = data.url;
                            this.loading = false;
                            this.success = true;
                        }).catch(() => {
                            this.loading = false;
                            this.error = true;
                        });
                    }
                }
            );
        },
        deleteInvoice(invoice) {
            this.$confirm.require(
                {
                    message: `Are you sure want to delete this invoice?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    accept: () => {
                        this.loading = true;
                        this.axios.post('./admin/invoices/delete.json', {
                            invoice_id: invoice.id
                        }).then(x => x.data).then(() => {
                            const indexToDelete = this.$refs.listTable.entries.findIndex(i => i.id === invoice.id);
                            if (indexToDelete !== -1) {
                                this.$refs.listTable.entries.splice(indexToDelete, 1);
                            }
                            this.loading = false;
                            this.success = true;
                        }).catch(() => {
                            this.loading = false;
                            this.error = true;
                        });
                    }
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped></style>
