<template>
    <div class="row full-page">
        <div class="col-lg-12">
            <list-table :endpoint="endpoint" :fields="fields" :sortDesc="true">
                <template #cell(artist_id)="data">
                    <a v-if="data.item.user.artist" target="_blank"
                       :href="'https://app.pipelinecrm.com/people/' + data.item.user.artist.artist_id">
                        {{ data.item.user.artist.artist_id }}
                    </a>
                </template>

                <template #cell(video)="data">
                    <a :href="data.item.url" class="ml-2" title="Show video" target="_blank">
                        <i class="fas fa-external-link-alt"></i>
                    </a>
                </template>
            </list-table>
        </div>
    </div>
</template>
<script>

import ListTable from '@/components/ListTable.vue';

export default {
    name: 'contracts',
    components: {ListTable},
    data() {
        return {
            endpoint: './admin/videos/list-raw.json',
            fields: [
                {key: 'id', label: '#', sortable: true},
                {key: 'title', label: 'Title', sortable: false},
                {key: 'video', label: 'Video', sortable: false},
                {key: 'user.name', label: 'Name', sortable: false},
                {key: 'user.email', label: 'Email', sortable: false},
                {key: 'artist_id', label: 'Artist id', sortable: false},
                {key: 'created_at', label: 'Created at', sortable: true},
            ],
        }
    },
};
</script>

<style lang="scss" scoped></style>
