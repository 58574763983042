<template>
    <list-table :endpoint="endpoint" :fields="fields" :showFilters="false">
        <template #header>
            <h3>Video Stats</h3>
        </template>

        <template #cell(video)="data">
            <div class="videos-list">
                <div class="d-inline-block mr-2 mb-1" v-for="(video, index) in data.item.project.videos"
                     v-bind:key="index">
                    <a :href="video.link" target="_blank">
                        <img class="img-fluid" :src="video.thumb" :title="video.title"
                             v-b-tooltip.right.hover>
                    </a>
                </div>
            </div>
        </template>

        <template #cell(published_at)="data">
            <div v-for="release in data.item.project.project_releases" v-bind:key="release.id">
                {{ release.published_at }}
            </div>
        </template>

        <template #cell(published_in)="data">
            <div v-for="release in data.item.project.project_releases" v-bind:key="release.id">
                <a :href="release.external_video_link" target="_blank">
                    {{ release.posted_channel_name }}
                </a>
            </div>
        </template>

        <template #cell(total_views)="data">
            <div v-if="payedForRelease">
                <div v-for="release in data.item.project.project_releases" v-bind:key="release.id">
                    {{ formatNumber(release.reward.totals.views) }}
                </div>
            </div>
            <div v-if="!payedForRelease">
                {{ formatNumber(data.item.totals.views) }}
            </div>
        </template>

        <template #cell(reward)="data">
            <div v-if="payedForRelease">
                <div v-for="release in data.item.project.project_releases" v-bind:key="release.id">
                    {{ formatReward(release.reward.reward) }}
                </div>
            </div>
            <div v-if="!payedForRelease">
                {{ formatReward(data.item.reward) }}
            </div>
        </template>

        <template #cell(total_reward)="data">
            <div v-if="payedForRelease">
                <div v-for="release in data.item.project.project_releases" v-bind:key="release.id">
                    {{ formatReward(release.reward.totals.reward) }}
                </div>
            </div>
            <div v-if="!payedForRelease">
                {{ formatReward(data.item.totals.reward) }}
            </div>
        </template>
    </list-table>
</template>
<script>

import {VBTooltip} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';
import ListTable from '@/components/ListTable.vue';

export default {
    name: 'videos',
    components: {ListTable},
    directives: {'b-tooltip': VBTooltip},
    mixins: [ProcessError],
    data() {
        return {
            endpoint: './user/rewards/list.json',
            fields: [
                {key: 'video', label: 'Video Project', sortable: false},
                {key: 'published_at', label: 'Date posted', sortable: false},
                {key: 'published_in', label: 'Posted at', sortable: false},
                {
                    key: 'total_views',
                    label: '3 sec views (monetized)',
                    sortable: false,
                    tdClass: 'text-right',
                    thClass: 'text-right'
                },
            ],
        };
    },
    mounted() {
        this.$store.dispatch('getUser').then((user) => {
            this.paymentType = user.active_contract ? user.active_contract.payment_type : null;
            if (this.paymentType !== this.$constants.paymentType.oneTimeFee) {
                this.fields.push({
                    key: 'reward',
                    label: this.$isExclusiveDashboard ? 'Your reward (current quarter)' : 'Your reward (this month)',
                    sortable: false,
                    tdClass: 'text-right',
                    thClass: 'text-right'
                });
                this.fields.push({
                    key: 'total_reward',
                    label: 'Your reward (total)',
                    sortable: false,
                    tdClass: 'text-right',
                    thClass: 'text-right'
                });
            }
        });
    },
    computed: {
        payedForRelease() {
            return parseInt(this.paymentType) !== this.$constants.paymentType.oneTimeFee;
        },
    },
    methods: {
        formatNumber(number) {
            return Number(number).toLocaleString();
        },
        formatReward(reward) {
            return '$' + Number(Number(reward).toFixed(2)).toLocaleString();
        },
    },
};
</script>
<style lang="scss" scoped>
.img-fluid {
    max-width: 50px;
}

.videos-list {
    max-width: 400px;
    overflow: hidden;
    line-break: anywhere;
    white-space: break-spaces;
}
</style>
