<template>
    <b-card>
        <b-alert v-model="success" variant="success">Successfully updated!</b-alert>
        <spinner v-model="loading"></spinner>
        <div class="row">
            <div class="col-xl-4 col-sm-12">
                <base-input
                    v-model="rewardsConfig.revenueCoefficient"
                    type="number"
                    :min="0"
                    :max="1"
                    :step="0.01"
                    :label="'Revenue coefficient'"
                    :error="errors.revenue_coefficient.message"
                    :input-classes="'form-control'"
                ></base-input>

                <div class="form-group">
                    <button @click="submit" class="btn btn-primary">Save</button>
                </div>
            </div>
        </div>

    </b-card>

</template>

<script>

import {BCard, BAlert} from 'bootstrap-vue';
import Spinner from '@/components/Spinner.vue';
import ProcessError from '@/mixins/ProcessError';

export default {
    name: 'rewards-config',
    components: {BCard, BAlert, Spinner},
    mixins: [ProcessError],
    data() {
        return {
            loading: false,
            success: false,
            rewardsConfig: {
                revenueCoefficient: null,
            },
            errors: {
                revenue_coefficient: {message: ''},
            }
        };
    },
    mounted() {
        this.fetchConfig();
    },
    methods: {
        fetchConfig() {
            this.resetErrors();
            this.loading = true;
            this.axios.get('./admin/settings/rewards.json').then(x => x.data).then((config) => {
                this.rewardsConfig.revenueCoefficient = config.revenue_coefficient;
                this.loading = false;
            }).catch((error) => {
                this.processError(error);
            });
        },
        submit() {
            this.resetErrors();
            this.success = false;
            this.loading = true;
            this.axios.post('./admin/settings/rewards.json', this.rewardsConfig).then(() => {
                this.loading = false;
                this.success = true;
            }).catch((error) => {
                this.processError(error);
            });
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
