<template>
    <b-modal id="new-video-modal" scrollable size="sm" hide-footer>
        <div class="row">
            <div class="col-lg-12">
                <spinner v-model="loading"></spinner>
                <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>

                <base-input v-model="videoData.title"
                            placeholder="Video title"
                ></base-input>

                <b-form-file v-model="videoData.file"
                             accept="video/*"
                             placeholder="Choose a file or drop it here..."
                             drop-placeholder="Drop file here..."
                ></b-form-file>
                <base-progress v-if="loading"
                               :type="'primary'"
                               :show-percentage="false"
                               :value="uploadPercentage"/>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-12 text-right modal-footer-border">
                <button type="button" @click="$bvModal.hide('new-video-modal')" class="btn btn-light mt-3">Close
                </button>
                <button type="button" @click="createVideo" class="btn btn-primary mt-3">Upload</button>
            </div>
        </div>
    </b-modal>
</template>

<script>

import {BAlert, BFormFile} from 'bootstrap-vue';
import Spinner from '@/components/Spinner.vue';
import ProcessError from '@/mixins/ProcessError';
import UploadFile from '@/mixins/UploadFile';

export default {
    name: 'new-raw-video',
    components: {BAlert, Spinner, BFormFile},
    mixins: [ProcessError, UploadFile],
    data() {
        return {
            loading: false,
            errors: {
                form: {show: false, message: ''},
            },
            videoData: {
                title: null,
                file: null,
            },
        }
    },
    methods: {
        uploadCallback(data) {
            this.axios.post('./user/videos/create-raw.json', {
                title: this.videoData.title,
                filename: data.filename
            }).then(x => x.data).then(() => {
                this.$emit('after-submit');
                this.$bvModal.hide('new-video-modal');
                this.loading = false;
            }).catch(error => {
                this.processError(error);
            });
        },
        createVideo() {
            this.resetErrors();
            if (!this.videoData.title) {
                this.errors.form.message = 'Title field is required!';
                this.errors.form.show = true;
                return;
            }
            if (!this.videoData.file) {
                this.errors.form.message = 'Please select file!';
                this.errors.form.show = true;
                return;
            }
            this.loading = true;
            this.uploadFile(this.videoData.file, this.uploadCallback);
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
