import { render, staticRenderFns } from "./SocialPages.vue?vue&type=template&id=ffc517ea&scoped=true"
import script from "./SocialPages.vue?vue&type=script&lang=js"
export * from "./SocialPages.vue?vue&type=script&lang=js"
import style0 from "./SocialPages.vue?vue&type=style&index=0&id=ffc517ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffc517ea",
  null
  
)

export default component.exports