<template>
    <b-modal id="video-releases" scrollable size="sm" hide-footer>
        <div class="row" v-if="video">
            <div class="col-lg-12">
                <b-alert v-model="errors.form.show" v-text="errors.form.message" variant="danger"></b-alert>
                <spinner v-model="loading"></spinner>
                <base-input
                    v-model="release.id"
                    placeholder="Video ID"
                    :label="'Released video id'"
                    :input-classes="'form-control'"
                    :class="'form-group mb-2'"
                    :error="errors.released_video_id.message"
                ></base-input>

                <div class="form-group mb-2">
                    <label class="form-control-label">Facebook page</label>
                    <multiselect
                        v-model="release.page"
                        :options="facebookPages"
                        label="name"
                        track-by="slug"
                        placeholder="Choose..."
                        :class="[{'is-invalid': errors.page.message}]"
                    ></multiselect>
                    <div class="invalid-feedback text-danger"
                         v-if="errors.page.message"
                         v-text="errors.page.message"
                    ></div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-12 text-right modal-footer-border">
                <button type="button" @click="closeModal" class="btn btn-light mt-3">Close</button>
                <button type="button" @click="addRelease" class="btn btn-primary mt-3">Save</button>
            </div>
        </div>
    </b-modal>
</template>

<script>

import {BAlert} from 'bootstrap-vue';
import ProcessError from '@/mixins/ProcessError';
import Spinner from '@/components/Spinner.vue'

export default {
    name: 'add-release',
    components: {BAlert, Spinner},
    mixins: [ProcessError],
    data() {
        return {
            video: null,
            facebookPages: null,
            loading: false,
            release: {
                id: null,
                page: null,
            },
            errors: {
                form: {message: '', show: false},
                released_video_id: {message: ''},
                page: {message: ''},
            }
        }
    },
    mounted() {
        this.getFacebookPages();
    },
    methods: {
        getFacebookPages() {
            this.axios.get('./admin/videos/facebook-pages.json').then(x => x.data).then((pages) => {
                this.facebookPages = pages;
                this.loading = false;
            }).catch(error => {
                this.processError(error);
            });
        },
        closeModal() {
            this.$bvModal.hide('video-releases');
        },
        openModal(video) {
            this.video = video;
            this.$bvModal.show('video-releases');
        },
        addRelease() {
            this.resetErrors();
            this.loading = true;
            this.axios.post('./admin/videos/release.json', {
                video_id: this.video.id,
                released_video_id: this.release.id,
                page: this.release.page ? this.release.page.slug : null,
            }).then(() => {
                this.closeModal();
                this.loading = false;
                this.video.id = null;
                this.release.id = null;
                this.release.page = null;
                this.$emit('after-submit');
            }).catch(error => {
                this.processError(error);
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.img-fluid {
    max-width: 150px;
}
</style>
