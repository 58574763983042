<template>
    <div id="app" ref="app" :class="$bodyClass">
        <ConfirmDialog></ConfirmDialog>
        <router-view/>
        <need-help></need-help>
    </div>
</template>

<script>
import NeedHelp from '@/components/NeedHelp.vue';
import ConfirmDialog from 'primevue/confirmdialog';

export default {
    components: {NeedHelp, ConfirmDialog},
};
</script>