<template>
    <div>
        <div class="header bg-white py-7 py-lg-8">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 col-md-6">
                            <h1 class="text-default">Welcome!</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </div>

        <div class="container mt--8 pb-5">
            <spinner v-model="loading"></spinner>
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7">
                    <div class="card bg-secondary shadow-lg border" v-if="$auth.ready()">
                        <div class="card-body px-lg-5 py-lg-5">
                            <b-alert v-model="showSuccess" v-text="successMessage"
                                     variant="success">
                            </b-alert>
                            <b-alert v-model="showError" v-text="errorMessage" variant="danger">
                            </b-alert>
                            <form role="form" @submit.prevent="login">
                                <base-input class="input-group-alternative mb-3"
                                            placeholder="Email"
                                            addon-left-icon="ni ni-email-83"
                                            v-model="email">
                                </base-input>

                                <base-input class="input-group-alternative"
                                            placeholder="Password"
                                            type="password"
                                            addon-left-icon="ni ni-lock-circle-open"
                                            v-model="password">
                                </base-input>

                                <base-checkbox v-model="remember" class="custom-control-alternative">
                                    <span class="text-muted">Remember me</span>
                                </base-checkbox>
                                <div class="text-center">
                                    <button type="submit" class="btn btn-primary my-4">Sign in</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-6">
                            <a href="#" @click.prevent="$bvModal.show('forgot-password')" class="text-light">
                                <small>Forgot password?</small>
                            </a>
                        </div>
                    </div>

                    <b-modal id="forgot-password" scrollable size="sm" hide-footer>
                        <div class="row">
                            <div class="col-lg-12">
                                <label class="form-control-label">Enter email address</label>
                                <base-input class="input-group-alternative mb-3"
                                            placeholder="Email"
                                            addon-left-icon="ni ni-email-83"
                                            v-model="emailToRemind">
                                </base-input>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-12 text-right modal-footer-border">
                                <button type="button"
                                        @click="$bvModal.hide('forgot-password')"
                                        class="btn btn-light mt-3">
                                    Close
                                </button>
                                <button type="button" @click="forgot" class="btn btn-primary mt-3">Submit</button>
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {BAlert} from 'bootstrap-vue';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'login',
    components: {BAlert, Spinner},
    data() {
        return {
            emailToRemind: '',
            email: '',
            password: '',
            remember: false,
            errorMessage: '',
            showError: false,
            showSuccess: false,
            successMessage: '',
            loading: false,
        }
    },
    methods: {
        forgot() {
            this.loading = true;
            this.axios.post('/auth/forgot.json', {email: this.emailToRemind}).then(x => x.data).then(data => {
                this.successMessage = data.message;
                this.showError = false;
                this.showSuccess = true;
                this.$bvModal.hide('forgot-password');
                this.loading = false;
            });
        },
        login() {
            const redirectObj = this.$auth.redirect();
            this.loading = true;
            this.$auth.login({
                data: {
                    email: this.email,
                    password: this.password,
                    remember: this.remember,
                },
                rememberMe: true,
            }).then(() => {
                this.$ga.event('User', 'Login', this.$auth.user().id.toString());
                this.$store.commit('set_user', this.$auth.user());
                if (this.$auth.check(this.$constants.role.admin)) {
                    this.$router.push(redirectObj ? redirectObj.from.path : 'artists');
                } else {
                    this.$router.push(redirectObj ? redirectObj.from.path : 'dashboard');
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.errorMessage = error.response.data.message;
                    this.showError = true;
                    this.showSuccess = false;
                }
                this.loading = false;
            });
        },
    },
}
</script>
<style>
</style>
