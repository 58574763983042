import axios from 'axios';

export default {
    state: {
        data: null,
        showRegisterAlert: false,
    },
    mutations: {
        set_user(state, user) {
            state.data = user;
        },
        show_register_alert(state, value) {
            state.showRegisterAlert = value;
        },
    },
    actions: {
        showRegisterAlert: ({commit}) => {
            commit('show_register_alert', true);
        },
        hideRegisterAlert: ({commit}) => {
            commit('show_register_alert', false);
        },
        getUser: async ({dispatch, state}) => {
            if (!state.data) {
                await dispatch('fetchUser');
            }

            return state.data;
        },
        fetchUser: async ({commit}) => {
            await axios.get('./auth/user.json').then(x => x.data).then((user) => {
                commit('set_user', user);
            }).catch(() => {
                commit('set_user', false);
            });
        },
    },
    getters: {
        user(state) {
            return state.data;
        },
        registerAlert(state) {
            return state.showRegisterAlert;
        },
    },
};
