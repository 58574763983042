<template>
    <div>
        <div class="row full-page">
            <div class="col-lg-12">
                <list-table ref="listTable" :endpoint="endpoint" :fields="fields" :requestParams="requestParams"
                            :sortDesc="true">
                    <template #header>
                        <div v-if="contract && contract.permission === $constants.permission.channel">
                            Even though you granted the rights to all of the videos in your channel, here you will only
                            see the ones that were selected by our research team and proposed to our content publishing
                            team. If the video is posted, the posting details will appear here as well – you will see
                            the publishing date and the page it's published on.
                        </div>
                        <div v-else>
                            All of your videos with permission granted to Bored Panda are here. In addition, you can see
                            their publish date and social media pages where they've been uploaded.
                        </div>
                    </template>
                    <template #cell(video)="data">
                        <a :href="data.item.link" target="_blank" :title="data.item.title" v-b-tooltip.right.hover>
                            <img class="img-fluid" :src="data.item.thumb">
                        </a>
                    </template>

                    <template #cell(permission_granted_at)="data">
                        <span v-if="data.item.permission_granted_at" v-text="data.item.permission_granted_at"></span>
                    </template>

                    <template #cell(published_at)="data">
                        <span class="d-block" v-for="project in data.item.projects" v-bind:key="project.id">
                            <span class="d-block" v-for="release in project.project_releases" v-bind:key="release.id">
                                {{ release.published_at }}
                            </span>
                        </span>
                    </template>

                    <template #cell(published_in)="data">
                        <span class="d-block" v-for="project in data.item.projects" v-bind:key="project.id">
                            <span class="d-block" v-for="release in project.project_releases" v-bind:key="release.id">
                               <a :href="release.external_video_link" target="_blank">
                                    {{ release.posted_channel_name }}
                                </a>
                            </span>
                        </span>
                    </template>

                    <template #cell(actions)="">
                        <a href="#"
                           title="If you want to change the permission type of this video please contact us at partnership@boredpanda.com"
                           v-b-tooltip.hover>
                            <i class="fas fa-info-circle"></i>
                        </a>
                    </template>
                </list-table>
            </div>
        </div>
    </div>
</template>

<script>

import ListTable from '@/components/ListTable.vue';
import {VBTooltip} from 'bootstrap-vue';

export default {
    name: 'granted-videos',
    components: {ListTable},
    directives: {'b-tooltip': VBTooltip},
    data() {
        return {
            contract: null,
            endpoint: './user/videos/list.json',
            fields: [
                {key: 'video', label: 'Video', sortable: false},
                {key: 'permission_granted_at', label: 'Copyright submission date', sortable: false},
                {key: 'published_at', label: 'Publish date', sortable: false},
                {key: 'published_in', label: 'Published in', sortable: false},
                {key: 'actions', label: '', sortable: false},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('getUser').then((user) => {
            this.contract = user.active_contract;
        });
    },
    computed: {
        requestParams() {
            return '&userId=' + (this.$auth.user() ? this.$auth.user().id : '');
        },
    }
};
</script>

<style lang="scss" scoped>
.img-fluid {
    width: 200px;
    max-width: 200px;
}
</style>
