<template>
    <base-nav class="navbar-top navbar-dark"
              id="navbar-main"
              :show-toggle-button="false"
              expand>
        <ul class="navbar-nav align-items-center mr-3 d-none d-md-flex ml-lg-auto">
            <li class="nav-item dropdown">
                <div class="media align-items-center" slot="title" v-if="$auth.check()">
                    <div class="media-body ml-2 text-white">
                        <span class="mb-0 text-sm font-weight-bold">{{ $auth.user().name }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </base-nav>
</template>
<script>

export default {
    data() {
        return {
            activeNotifications: false,
            showMenu: false,
        };
    },
    methods: {
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        }
    }
};
</script>
