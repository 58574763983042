import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import constants from '@/constants';
import axios from 'axios';

Vue.use(Router)
axios.interceptors.request.use(
    (config) => {
        if (config.url.indexOf('auth/login.json') === -1) {
            if (config.method === 'get') {
                const url = config.url.split('?');
                let searchParams = new URLSearchParams(url[1]);
                searchParams.set('dashboard_type', localStorage.dashboardType);
                config.url = url[0] + '?' + searchParams.toString();
            } else {
                if (config.data === undefined) {
                    config.data = {};
                }
                config.data.dashboard_type = localStorage.dashboardType;
            }
        }

        return config;
    }
);

function loadView(view) {
    return () => import(/* webpackChunkName: "[request]" */ `./views/${view}.vue`)
}

const router = new Router({
    mode: 'history', linkExactActiveClass: 'active', routes: [{
        path: '/logged', redirect: 'dashboard', component: DashboardLayout, meta: {auth: true}, children: [{
            path: '/dashboard', name: 'dashboard', component: loadView('Dashboard'), meta: {auth: true},
        }, {
            path: '/library', name: 'my library', component: loadView('Library'), meta: {auth: true},
        }, {
            path: '/rewards', name: 'my rewards', component: loadView('Rewards'), meta: {auth: true},
        }, {
            path: '/my-agreement', name: 'my agreement', component: loadView('Contract'), meta: {auth: true},
        }, {
            path: '/my-details', name: 'my account details', component: loadView('Details'), meta: {auth: true},
        }, {
            path: '/my-all-payments', name: 'my all payments', component: loadView('AllPayments'), meta: {auth: true},
        }, {
            path: '/submit-video', name: 'submit video', component: loadView('SubmitVideo'), meta: {auth: true},
        }, {
            path: '/faq', name: 'FAQ', component: loadView('Faq'), meta: {auth: true},
        }, {
            path: '/artists',
            name: 'artists',
            component: loadView('Artists'),
            meta: {auth: {roles: constants.role.admin}},
        }, {
            path: '/videos', name: 'videos', component: loadView('Videos'), meta: {auth: {roles: constants.role.admin}},
        }, {
            path: '/raw-videos',
            name: 'raw videos',
            component: loadView('RawVideos'),
            meta: {auth: {roles: constants.role.admin}},
        }, {
            path: '/settings',
            name: 'settings',
            component: loadView('Settings'),
            meta: {auth: {roles: constants.role.admin}},
        }, {
            path: '/contracts',
            name: 'contracts',
            component: loadView('Contracts'),
            meta: {auth: {roles: constants.role.admin}},
        }, {
            path: '/invoices',
            name: 'invoices',
            component: loadView('Invoices'),
            meta: {auth: {roles: constants.role.admin}},
        },]
    }, {
        path: '/', redirect: 'home', component: AuthLayout, meta: {auth: null}, children: [{
            path: '/home', name: 'homepage', component: loadView('Homepage'), meta: {auth: null},
        }, {
            path: '/login', name: 'login', component: loadView('Login'), meta: {auth: false},
        }, {
            path: '/password-reset', name: 'password reset', component: loadView('PasswordReset'), meta: {auth: false},
        }, {
            path: '/register', name: 'register', component: loadView('Register'), meta: {auth: false},
        }, {
            path: '/agreement', name: 'agreement', component: loadView('Agreement'), meta: {auth: true},
        }, {
            path: '/403', component: loadView('Unauthorized')
        }, {
            path: '/privacy-policy', component: loadView('PrivacyPolicy')
        }, {
            path: '/terms-and-conditions', component: loadView('TermsAndConditions')
        }, {
            path: '/social-pages', component: loadView('SocialPages')
        }, {
            path: '/video-submission-bonus', component: loadView('VideoSubmissionBonus')
        }, {
            path: '/confirm-agreement', component: loadView('ConfirmAgreement')
        }, {
            path: '*', component: loadView('NotFound')
        },]
    },]
});

router.beforeEach((to, from, next) => {
    const store = router.app.$store;
    const constants = router.app.$constants;
    const ga = router.app.$ga;
    const toggleAnalytics = (user) => {
        if (user && user.roles.includes(constants.role.admin)) {
            ga.disable();
        } else {
            ga.enable();
            if (user) {
                ga.set('dimension1', user.id.toString());
                ga.set('dimension2', user.name);
            }
        }
    };

    store.dispatch('getUser').then(() => {
        const user = store.getters.user;
        toggleAnalytics(user);
        if (user) {
            if (
                !user.roles.includes(constants.role.admin) &&
                !user.is_contract_agreed &&
                to.name !== 'agreement' &&
                to.meta &&
                to.meta.auth
            ) {
                next('agreement');
            } else if (!user.is_active && to.name !== 'homepage') {
                next('home');
            } else if (user.roles.includes(constants.role.admin) && (['login', 'register'].includes(to.name) || to.path === '/')) {
                next('artists');
            } else if (!user.roles.includes(constants.role.admin) && (['login', 'register'].includes(to.name) || to.path === '/')) {
                next('dashboard');
            } else {
                next();
            }
        } else if (to.meta.auth === true && to.name !== 'login') {
            next('login');
        } else if (to.path === '/') {
            next('home');
        } else {
            next();
        }
    });

});

export default router;
