<template>
    <div>
        <div class="row">
            <div class="col-lg-12 mb-5 mb-xl-0">
                <tabs v-if="$auth.check($constants.role.exclusive_user)" fill justified class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Videos with granted permission">
                            <granted-videos></granted-videos>
                        </tab-pane>
                    </card>
                </tabs>
                <granted-videos v-else></granted-videos>
            </div>
        </div>
    </div>
</template>
<script>
import GrantedVideos from './Library/GrantedVideos.vue';

export default {
    components: {GrantedVideos},
};
</script>
<style lang="scss" scoped>
</style>
