import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import auth from './auth';
import constants from './constants';
import VueAuth from '@websanova/vue-auth/src/v2';
import './registerServiceWorker';
import ArgonDashboard from './plugins/argon-dashboard';
import VueAnalytics from 'vue-analytics';

const isProd = process.env.NODE_ENV === 'production';

Vue.config.productionTip = isProd;
Vue.prototype.$constants = constants;

Vue.use(ArgonDashboard);
Vue.use(VueAuth, auth);
store.dispatch('getUser').then((user) => {
  store.commit('set_user', false);
  if (user.has_exclusive_contract && user.has_non_exclusive_contract) {
    localStorage.dashboardType = localStorage.dashboardType || constants.dashboardType.non_exclusive;
  } else if (user.has_exclusive_contract) {
    localStorage.dashboardType = constants.dashboardType.exclusive;
  } else {
    localStorage.dashboardType = constants.dashboardType.non_exclusive;
  }
  Vue.prototype.$bodyClass = localStorage.dashboardType || '';
  Vue.prototype.$isExclusiveDashboard = localStorage.dashboardType === constants.dashboardType.exclusive;
  Vue.use(VueAnalytics, {
    id: 'UA-204916774-1',
    router,
    autoTracking: {
      exception: true,
    },
    debug: {
      enabled: !isProd,
      sendHitTask: isProd,
    },
    disabled: () => {
      return user && user.roles.includes(constants.role.admin);
    }
  });

  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});
