export default {
    roleName: {
        partnership_admin: 'Admin',
        partnership_super_admin: 'Super Admin',
        partnership_user: 'User',
    },
    role: {
        admin: 'partnership_admin',
        super_admin: 'partnership_super_admin',
        user: 'partnership_user',
        exclusive_user: 'partnership_exclusive_user',
    },
    permission: {
        video: 0,
        channel: 1,
    },
    contractType: {
        non_exclusive: 0,
        exclusive: 1,
    },
    paymentType: {
        free: 0,
        oneTimeFee: 1,
        revenueShare: 2,
        internalRPM: 3,
        stairs: 4,
    },
    contractStatus: {
        new: 0,
        agreed: 1,
        terminated: 2,
    },
    contractStatusName: {
        0: 'New',
        1: 'Agreed',
        2: 'Terminated',
    },
    billingMethod: {
        paypal: 1,
        bank: 2,
    },
    billingMethodName: {
        1: 'PayPal',
        2: 'Bank transfer',
    },
    currencies: {
        usd: 0,
        eur: 1,
        gbp: 2
    },
    currencyName: {
        0: 'USD',
        1: 'EUR',
        2: 'GBP'
    },
    channels: {
        craftyPanda: 1,
        ladyPanda: 2
    },
    channelName: {
        1: 'Crafty Panda',
        2: 'Lady Panda'
    },
    deal_permission_type: {
        channel: 0,
        platform: 1,
    },
    contractVideoStatus: {
        active: 0,
        terminated: 1,
    },
    contractVideoStatusName: {
        0: 'Active',
        1: 'Terminated',
    },
    dashboardType: {
        exclusive: 'exclusive',
        non_exclusive: 'non-exclusive',
    },
    agreementStatus: {
        pending: 0,
        accepted: 1,
        declined: 2,
    }
}
