<template>
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
        <side-bar :background-color="sidebarBackground">
            <template slot="links">
                <sidebar-item :link="{name: 'Dashboard', icon: 'ni ni-chart-bar-32 text-primary', path: '/dashboard'}"/>
                <sidebar-item :link="{name: 'My Library', icon: 'ni ni-books text-blue', path: '/library'}"/>
                <sidebar-item v-if="paidUser" :link="{name: 'My Rewards', icon: 'ni ni-money-coins text-yellow', path: '/rewards'}"/>
            </template>
            <template slot="settings-links">
                <sidebar-item
                    :link="{name: 'My Agreement', icon: 'fas fa-file-contract text-primary', path: '/my-agreement'}"/>
                <sidebar-item :link="{name: 'My Account Details', icon: 'fas fa-address-book text-blue', path: '/my-details'}"/>
                <sidebar-item v-if="paidUser" :link="{name: 'FAQ', icon: 'fas fa-question-circle text-yellow', path: '/faq'}"/>
            </template>
            <template slot="payments" v-if="paidUser">
                <sidebar-item :link="{name: 'My All Payments', icon: 'fas fa-dollar-sign text-warning', path: '/my-all-payments'}"/>
            </template>
            <template slot="admin-links">
                <sidebar-item :link="{name: 'Artists', icon: 'ni ni-single-02 text-yellow', path: '/artists'}"/>
                <sidebar-item
                    :link="{name: 'Contracts', icon: 'fas fa-file-contract text-primary', path: '/contracts'}"/>
                <sidebar-item :link="{name: 'Invoices', icon: 'fas fa-file-invoice-dollar text-blue', path: '/invoices'}"/>
                <sidebar-item :link="{name: 'Raw Videos', icon: 'ni ni ni-cloud-upload-96 text-yellow', path: '/raw-videos'}"/>
                <sidebar-item :link="{name: 'Videos', icon: 'ni ni-button-play text-green', path: '/videos'}"/>
                <sidebar-item :link="{name: 'Settings', icon: 'ni ni-settings text-red', path: '/settings'}"/>
            </template>
            <template slot="submit-video">
                <sidebar-item :link="{name: 'Submit Your New Video', icon: 'ni  ni-cloud-upload-96 text-success', path: '/submit-video'}"/>
            </template>
        </side-bar>

        <div class="main-content" :data="sidebarBackground">
            <dashboard-navbar></dashboard-navbar>

            <div @click="toggleSidebar">
                <base-header type="gradient-default" class="pb-6 pb-8 pt-5 pt-md-8">
                    <fade-transition :duration="200" origin="center top" mode="out-in">
                        <!-- your content here -->
                        <router-view></router-view>
                    </fade-transition>
                </base-header>
            </div>
        </div>
    </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue';
import {FadeTransition} from 'vue2-transitions';

export default {
    components: {
        DashboardNavbar, FadeTransition
    },
    data() {
        return {
            user: null,
            sidebarBackground: 'orange' //vue|blue|orange|green|red|primary
        };
    },
    computed: {
        paidUser() {
            if (this.user && this.user.contracts) {
                return this.user.contracts.filter((contract) => {
                    return contract.status !== this.$constants.contractStatus.new &&
                        contract.payment_type !== this.$constants.paymentType.free;
                }).length > 0;
            }

            return false;
        },
    },
    async mounted() {
        this.user = await this.$store.dispatch('getUser');
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        }
    }
};
</script>
<style lang="scss">
</style>
