<template>
    <div class="row full-page">
        <div class="col-lg-12">
            <list-videos></list-videos>
        </div>
    </div>
</template>
<script>

import ListVideos from './Videos/ListVideos.vue';

export default {
    components: {
        ListVideos
    },
};
</script>

<style lang="scss" scoped></style>
