import Vue from 'vue'
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';
import router from '@/router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import snakecaseKeys from 'snakecase-keys'


axios.defaults.baseURL = process.env.VUE_APP_BASE_BACKEND_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.transformRequest = [(data) => {
  if (data) {
    return JSON.stringify(snakecaseKeys(data, { deep: true }));
  }
}];

Vue.router = router;
Vue.use(VueAxios, axios);

export default {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    registerData: {url: 'auth/register.json', method: 'POST', autoLogin: true, fetchUser: true},
    loginData: {url: 'auth/login.json', method: 'POST', fetchUser: true},
    logoutData: {url: 'auth/logout.json', method: 'POST', redirect: '/login', makeRequest: true},
    fetchData: {url: 'auth/me.json', method: 'GET', enabled: true},
    refreshData: {url: 'auth/refresh.json', method: 'POST', enabled: true, interval: 30}
  },
};
