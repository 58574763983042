<template>
    <div class="container">
        <div class="row pt-8">
            <div class="col-lg-12 pt-7 text-center">
                <h1 class="heading-title text-secondary">Unauthorized!</h1>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'not-found',
    data() {
        return {
            model: {
                email: '',
                password: ''
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.bottom {
    bottom: 0;
}
</style>
