<template>
    <div class="row full-page">
        <div class="col-lg-12">
            <users></users>
        </div>
        <div class="col-lg-12" v-if="superAdmin">
            <rewards-config></rewards-config>
        </div>
    </div>
</template>
<script>

import Users from './Settings/Users.vue';
import RewardsConfig from './Settings/RewardsConfig.vue';

export default {
    components: {
        RewardsConfig,
        Users
    },
    computed: {
        superAdmin() {
            return this.$auth.check(this.$constants.role.super_admin);
        },
    },
};
</script>

<style lang="scss" scoped></style>
